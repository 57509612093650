import { __decorate } from "tslib";
import { Component, Prop, Ref, Emit, } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import UserFormBase from './user-form/user-form-base';
import { OperateType, UserPageType, SourceType, UnitType, } from '../../../typings/organization/org-manage';
import moment from 'moment';
import { Loading } from '@cloudpivot-hermes/common-components';
let UserDetail = class UserDetail extends UserFormBase {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.saveLoading = false;
        this.isEdit = false;
        this.OperateType = OperateType;
        this.userDetailOriginalData = {};
    }
    // 是否是云枢自维护的组织部门
    get isSelfMaintainType() {
        return this.userDetailOriginalData?.sourceType === SourceType.SelfMaintain && !!this.userId;
    }
    created() {
        this.initUserDetail();
    }
    editUser() {
        this.isEdit = true;
    }
    /**
     * 初始化人员数据
     */
    initUserDetail() {
        this.loading = true;
        let getUserDetail = function () { };
        if (this.userId) {
            getUserDetail = this.initUserDetailWidthSingleOrg;
        }
        else if (this.multipleOrgParams) {
            getUserDetail = this.initUserDetailWidthMultiOrg;
        }
        getUserDetail().finally(() => {
            this.loading = false;
        });
    }
    /**
     * 初始化单组织的用户详情
     */
    initUserDetailWidthSingleOrg() {
        return this.getUserDetail().then((userDetailOriginalData) => {
            this.userDetailOriginalData = userDetailOriginalData;
            this.isEdit = this.isEditUser;
            this.generateUserDetail(userDetailOriginalData);
        });
    }
    /**
     * 初始化多组织的用户详情
     */
    initUserDetailWidthMultiOrg() {
        return this.getUserDetailWidthMultiOrg().then((userDetailOriginalData) => {
            this.userDetailOriginalData = userDetailOriginalData;
            this.generateUserDetail(userDetailOriginalData);
        });
    }
    /**
     * 生成人员的组件数据
     */
    generateUserDetail(userDetailOriginalData = {}) {
        let orgInfoLists = [];
        if (this.userId) {
            this.form.baseUserInfo = this.generateBaseUserInfo(userDetailOriginalData);
            const singleOrgInfo = this.generateSingleOrgInfo(userDetailOriginalData);
            orgInfoLists = [singleOrgInfo];
        }
        else if (this.multipleOrgParams) {
            this.form.baseUserInfo = this.generateBaseUserInfo(userDetailOriginalData.baseUser);
            const userOrgs = userDetailOriginalData?.orgUsers || [];
            orgInfoLists = (Array.isArray(userOrgs) && userOrgs.map((item) => {
                return this.generateSingleOrgInfo(item);
            })) || [];
        }
        this.form.orgInfoList = super.generateOrgInfo(orgInfoLists);
        super.setStaffSelectorDisabledKeys();
    }
    /**
     * 生成用户的基本信息
     */
    generateBaseUserInfo(userDetailOriginalData) {
        return {
            id: userDetailOriginalData.id,
            imgUrl: userDetailOriginalData.imgUrl,
            name: userDetailOriginalData.name,
            account: userDetailOriginalData.username,
            mobile: userDetailOriginalData.mobile,
            sex: userDetailOriginalData.gender,
        };
    }
    /**
     * 生成多组织组件的数据
     */
    generateSingleOrgInfo(userDetailOriginalData = {}) {
        const partTimeDeptDetail = userDetailOriginalData.partTimeDepartments || [];
        const partTimeDeparts = [];
        // 兼职部门信息
        for (const item of partTimeDeptDetail) {
            const partTimeDepart = [];
            // 兼职部门
            if (item.departmentId) {
                partTimeDepart.push({
                    id: item.departmentId,
                    name: item.departmentName,
                    type: UnitType.Dept,
                });
            }
            // 兼职部门直属上级
            const partTimeDepartSuperior = [];
            if (item.parentUserId) {
                partTimeDepartSuperior.push({
                    id: item.parentUserId,
                    name: item.parentUserName,
                    type: UnitType.User,
                });
            }
            partTimeDeparts.push({
                partTimeRandomId: `part-time-depart-${+new Date()}-${Math.floor(Math.random() * 10000000000)}`,
                // 兼职部门
                partTimeDepart: partTimeDepart,
                partTimeDepartDisabledKeys: [],
                partTimeDepartPath: item.departmentPathName,
                // 兼职部门上级
                partTimeDepartSuperior: partTimeDepartSuperior,
            });
        }
        // 主部门信息
        const mainDepart = [];
        if (userDetailOriginalData.mainDepartmentId) {
            mainDepart.push({
                id: userDetailOriginalData.mainDepartmentId,
                name: userDetailOriginalData.mainDepartmentName,
                type: UnitType.Dept,
            });
        }
        // 主部门直属上级
        const mainDepartSuperior = [];
        if (userDetailOriginalData.parentUserId) {
            mainDepartSuperior.push({
                id: userDetailOriginalData.parentUserId,
                name: userDetailOriginalData.parentUserName,
                type: UnitType.User,
            });
        }
        const entryDate = userDetailOriginalData.entryDate
            ? moment(userDetailOriginalData.entryDate, 'YYYY-MM-DD') : '';
        // 组织信息
        const orgInfo = {
            // 组织
            orgInfo: {
                id: userDetailOriginalData.orgId,
                name: userDetailOriginalData.organizationName,
                type: UnitType.Dept,
            },
            // 主部门信息
            mainDepart: mainDepart,
            mainDepartDisabledKeys: [],
            mainDepartPath: userDetailOriginalData.mainDepartmentFullNamePath,
            // 主部门直属上级
            mainDepartSuperior: mainDepartSuperior,
            // 兼职部门信息
            partTimeDeparts: partTimeDeparts,
            departTags: userDetailOriginalData?.labels?.map(item => item.name),
            position: userDetailOriginalData.position,
            entryDate: entryDate,
            employeeNo: userDetailOriginalData.employeeNo,
            officePhone: userDetailOriginalData.officePhone,
            email: userDetailOriginalData.email,
        };
        return orgInfo;
    }
    /**
     * 通过ID获取用户详情
     */
    getUserDetail() {
        return OrgManageService.getUserDetail({ id: this.userId }).then((res) => {
            if (res.success) {
                return res.data;
            }
        });
    }
    /**
     * 获取多组织的用户详情
     */
    getUserDetailWidthMultiOrg() {
        return OrgManageService.getUserDetailWidthMultiOrg({ ...this.multipleOrgParams }).then((res) => {
            if (res.success) {
                return res.data;
            }
        });
    }
    /**
     * 删除人员
     */
    delUser() {
        this.$confirm({
            title: '确定删除该人员吗？',
            content: '删除后，当前部门该人员信息将会被清除。',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                return new Promise((resolve, reject) => {
                    OrgManageService.deleteUser({
                        id: this.userDetailOriginalData.id,
                        deptId: this.selectedDeptDetail.id,
                    }).then((res) => {
                        if (res.success) {
                            resolve(null);
                            this.$message.success('删除成功');
                            this.toggleComponent();
                        }
                    }).catch((e) => {
                        reject(e);
                    });
                });
            },
        });
    }
    toggleComponent() {
        return {
            userDetail: null,
            showUserPageType: UserPageType.UserList,
        };
    }
    goBack() {
        this.toggleComponent();
    }
    /**
     * 保存编辑的人员
     */
    onSave() {
        const isValid = this.userForm.cform.validate();
        if (!isValid)
            return;
        const orgInfoList = this.form.orgInfoList[0];
        const mainDepart = orgInfoList.mainDepart;
        const partTimeDeparts = orgInfoList.partTimeDeparts;
        const hasMainDepart = !!mainDepart.length;
        const hasPartTimeDepart = partTimeDeparts?.some((item) => {
            return !!item.partTimeDepart.length;
        });
        if (!hasMainDepart && !hasPartTimeDepart) {
            this.$message.warning('主部门和兼职部门不能同时为空');
            return;
        }
        ;
        this.saveLoading = true;
        super.submit(OrgManageService.updateUser).then((res) => {
            if (res.success) {
                return this.getUserDetail();
            }
        }).then((userDetailOriginalData) => {
            this.isEdit = false;
            this.userDetailOriginalData = userDetailOriginalData;
            this.generateUserDetail(userDetailOriginalData);
            this.$message.success('编辑成功');
        }).finally(() => {
            this.saveLoading = false;
        });
    }
    /**
     * 取消编辑
     */
    cancelEdit() {
        this.isEdit = false;
        this.userForm.cform.clearValidate();
        // 取消编辑重置一下用户数据
        this.generateUserDetail(this.userDetailOriginalData);
    }
};
__decorate([
    Ref()
], UserDetail.prototype, "userForm", void 0);
__decorate([
    Prop()
], UserDetail.prototype, "multipleOrgParams", void 0);
__decorate([
    Prop()
], UserDetail.prototype, "userId", void 0);
__decorate([
    Prop({ default: () => { } })
], UserDetail.prototype, "selectedDeptDetail", void 0);
__decorate([
    Prop({ default: false })
], UserDetail.prototype, "isEditUser", void 0);
__decorate([
    Emit('toggleComponentEvent')
], UserDetail.prototype, "toggleComponent", null);
UserDetail = __decorate([
    Component({
        name: 'UserDetail',
        components: {
            UserForm: () => import('./user-form/index.vue'),
            Loading,
        },
    })
], UserDetail);
export default UserDetail;
